import React from 'react';

import LoggedPage from '@components/containers/LoggedPage';
import InsuranceCard from '@components/modules/InsuranceCard';
import NoInsuranceCard from '@components/modules/NoInsuranceCard';

import Loader from '@components/elements/Loader';

import urls from '@constants/urls';

import useGetInsurances from '@utils/useGetInsurances';
import getUrl from '@utils/getUrl';

const MyInsurances = () => {
  const { insurances, loading } = useGetInsurances();

  return (
    <LoggedPage
      title="Mina försäkringar"
      previousUrl={getUrl(urls.HOME)}
    >
      {loading ? <Loader /> : (
        <>
          {Object.values(insurances).map((insurance) => (
            <InsuranceCard insuranceId={insurance.id} key={insurance.publicId} />
          ))}
          {Object.keys(insurances).length ? null : <NoInsuranceCard />}
        </>
      )}
    </LoggedPage>
  );
};

export default MyInsurances;
