import React from 'react';

import Card from '@components/containers/Card';
import FlexBox from '@components/containers/FlexBox';

import Paragraph from '@components/elements/Paragraph';
import Headline from '@components/elements/Headline';

const NoInsuranceCard = () => (
  <Card>
    <FlexBox marginBottom="regular">
      <Headline fontWeight="bold" fontSize="tiny" as="h2" marginBottom="none" marginRight="small">Låneskydd</Headline>
    </FlexBox>
    <FlexBox alignItems="center" flexDirection="column">
      <Paragraph>Se till att du alltid klarar av dina månadsbetalningar. Kontakta kundtjänst för att försäkra ditt lån.</Paragraph>
    </FlexBox>
  </Card>
);

export default NoInsuranceCard;
